<section class="content">
    <div class="block-content">
        <h3 class="block-title">Contacto</h3>
        <div class="row">
            <div class="col-md-6">
                <!-- <form class="contact-form" action="php/send_mail.php" method="post" id="contact_form">
                  <div class="form-control-wrap">
                      <div id="message" class="alert alert-danger alert-dismissible fade"></div>
                      <div class="form-group">
                          <input type="text" class="form-control" id="fname" placeholder="Nombre*" name="fname">
                      </div>
                      <div class="form-group mar-top-40">
                          <input type="email" class="form-control" id="email" placeholder="Correo Electronico*" name="email">
                      </div>
                      <div class="form-group mar-top-60">
                          <textarea class="form-control" rows="10" name="comment" id="comment" placeholder="Su Mensaje"></textarea>
                      </div>
                      <div class="form-group mar-top-40">
                          <button type="submit" class="btn v7">Enviar Mensaje</button>
                      </div>
                  </div>
              </form> -->
                <form class="contact-form" action="https://formspree.io/f/xgedgjzr" method="POST">
                    <div class="form-control-wrap">
                        <div class="form-group">
                            <input type="email" name="email" type="email" class="form-control" id="email"
                                placeholder="Correo Electronico*" required>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" rows="10" name="comment" id="comment" name="message"
                                placeholder="Su Mensaje" required></textarea>
                        </div>
                        <!-- your other form fields go here -->
                        <button type="submit" class="btn v7">Enviar Mensaje</button>
                    </div>
                </form>
            </div>

            <div class="col-md-5 offset-md-1">
                <div class="contact-content">
                    <div class="contact-icon">
                        <i class="ion-ios-location-outline"></i>
                    </div>
                    <div class="contact-details">
                        <h5>Dirección</h5>
                        <p>Griñon, Madrid, ESPAÑA</p>
                    </div>
                </div>
                <div class="contact-content">
                    <div class="contact-icon">
                        <i class="ion-ios-telephone-outline"></i>
                    </div>
                    <div class="contact-details">
                        <h5>Telefono</h5>
                        <p> <a href="tel:+51959747175">+34 - 684 36 10 67</a></p>
                    </div>
                </div>
                <div class="contact-content">
                    <div class="contact-icon">
                        <i class="ion-ios-email-outline"></i>
                    </div>
                    <div class="contact-details">
                        <h5>Correo</h5>
                        <p>fabienmrobin@gmail.com</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>