import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CvabastidasService {

  profesional: any[] = [];
  educacion: any[] = [];
  testimonio: any[] = [];
  experiencia: any[] = [];
  blog: any[] = [];

  constructor(private http: HttpClient) {
    this.CargarProfesional();
    this.CargarEducacion();
    // this.CargarTestimonio();
     this.CargarExperiencia();
    this.CargarBlog();
  }

  private CargarProfesional(){
    this.http.get('https://portafolio-e1d49-default-rtdb.europe-west1.firebasedatabase.app/pro.json')
      .subscribe((resp: any[]) => {
        this.profesional = resp;
        console.log(resp);
    });
  }

  private CargarEducacion(){
    this.http.get('https://portafolio-e1d49-default-rtdb.europe-west1.firebasedatabase.app/educa.json')
      .subscribe((resp: any[]) => {
        this.educacion = resp;
        console.log(resp);
    });
  }

  private CargarTestimonio(){
    this.http.get('')
      .subscribe((resp: any[]) => {
        this.testimonio = resp;
        console.log(resp);
    });
  }

  private CargarExperiencia(){
    this.http.get('https://portafolio-e1d49-default-rtdb.europe-west1.firebasedatabase.app/experiencia.json')
      .subscribe((resp: any[]) => {
        // this.experiencia = resp;
        resp.forEach(line => {
          // console.log(line.Descripcion);
          line.Descripcion=line.Descripcion.split('*');
        });
        this.experiencia = resp;
         console.log(this.experiencia);
    });
  }

  private CargarBlog(){
    this.http.get('https://portafolio-e1d49-default-rtdb.europe-west1.firebasedatabase.app/blog.json')
      .subscribe((resp: any[]) => {
        this.blog = resp;
        console.log(resp);
    });
  }

}


