<section class="content">
  <div class="block-content">
      <h3 class="block-title">Mi Blog</h3>
      <div class="row">
          <div class="col-md-10 mx-auto">
            <!-- <img src="assets\images\en_consturccion.jpg" alt=""> -->
              <div *ngFor="let blox of cv.blog">
                <div class="post">
                  <div class="post-thumbnail">
                       <div class="embed-responsive">
                          <img src="assets\images\blog\{{blox.img}}" style="max-width: 50%;" alt="">
                      </div> 
                  </div> 
                  <div class="post-title">
                      <a href="#"><h2>{{ blox.titulo }}</h2></a>
                      <p class="post-info">
                          <span class="post-author">{{ blox.autor }}</span>
                          <span class="slash"></span>
                          <span class="post-date">{{ blox.fecha }}</span>
                          <span class="slash"></span>
                          <span class="post-catetory">{{ blox.categoria }}</span>
                      </p>
                  </div>
                  <div class="post-body">
                      <a class="btn open-post" id="{{ blox.url }}" href="../../index.html">Ver Mas</a>
                  </div>
                </div>
              </div> 
          </div>
      </div>
  </div>
</section>
