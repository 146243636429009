  <section class="content" id="about">
      <div class="block-content">
          <h3 class="block-title">Perfil</h3>
          <h3 class="subheading">¡Hola! soy Fabien Marcos Robin<br> <strong>Analista & Desarrollador BackEnd/FrontEnd</strong></h3>
          <p>Desarrollador Web Senior con más de 4 años de experiencia.
            Especializado en desarrollo front end y back end.
            Experimentado con todas las etapas del ciclo de desarrollo de proyectos web dinámicos.
            Tengo conocimientos profundos en HTML5 avanzado, PHP, LARAVEL, SYMFONY, ANGULAR, CSS, CSS3, JSON, XML, JavaScript, JQuery.</p>
          <div class="info-list row">
              <div class="col-sm-6"><span>Nombre : </span> Fabien MARCOS ROBIN</div>
              <div class="col-sm-6"><span>Fecha de Nacimiento : </span>22 Mayo 1987 </div>
              <div class="col-sm-6"><span>Dirección : </span> Griñon, Madrid, ESPAÑA.</div>
              <div class="col-sm-6"><span>Email : </span> fabienmrobin@gmail.com</div>
              <div class="col-sm-6"><span>Telefono : </span> + 34 684 36 10 67</div>
              <div class="col-sm-6"><span>Interes : </span> Frelance, Full-Time</div>
          </div>
      </div>
      <div class="block-content">
          <h3 class="block-title">Profesional</h3>
          <div *ngFor="let profex of cv.profesional">
            <label class="progress-bar-label">{{ profex.nombre }}</label>
            <div class="progress">
                <div [style.width.%]=profex.valor class="progress-bar" role="progressbar" attr.aria-valuenow="{{ profex.valor }}" aria-valuemin="0" aria-valuemax="100">
                    <span>{{ profex.valor }}%</span>
                </div>
            </div>
        </div>
      </div>
      <div class="block-content">
          <h3 class="block-title">Experiencia</h3>
          <div class="timeline experience">
              <div class="row">
                  <div class="col-md-12">
                      <div class="exp-holder">
                          <div *ngFor="let expex of cv.experiencia">
                            <div class="exp">
                                <div class="hgroup">
                                    <h4><span>{{ expex.Empresa }}</span> - {{ expex.Cargo }}</h4>
                                    <h5> {{ expex.Fecha }} </h5>
                                </div>
                                <ul style="list-style-type: square;">
                                    <li *ngFor="let desc of expex.Descripcion">{{ desc }}</li>
                                </ul>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="block-content">
          <h3 class="block-title">Educación</h3>
          <div class="timeline education">
              <div class="row">
                  <div class="col-md-12">
                      <div class="exp-holder">
                        <div *ngFor="let edux of cv.educacion">
                          <div class="exp">
                              <div class="hgroup">
                                  <h4>{{ edux.Nombre }}</h4>
                                  <h5>{{ edux.Fecha }}</h5>
                              </div>
                              <p>{{ edux.Descripcion }}</p>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="block-content">
          <h3 class="block-title">Servicios</h3>
          <div class="row">
              <div class="col-md-4 col-sm-6 service">
                  <div class="service-icon">
                      <i class="ion-laptop"></i>
                  </div>
                  <h4>Diseño Web</h4>
              </div>
              <div class="col-md-4 col-sm-6 service">
                  <div class="service-icon">
                      <i class="ion-pie-graph"></i>
                  </div>
                  <h4>Desarrollo de Aplicaciones</h4>
              </div>
              <div class="col-md-4 col-sm-6 service">
                  <div class="service-icon">
                      <i class="ion-paintbucket"></i>
                  </div>
                  <h4>Diseño Grafico</h4>
              </div>
              <div class="col-md-4 col-sm-6 service">
                  <div class="service-icon">
                      <i class="ion-printer"></i>
                  </div>
                  <h4>Print Design</h4>
              </div>
              <div class="col-md-4 col-sm-6 service">
                  <div class="service-icon">
                      <i class="ion-qr-scanner"></i>
                  </div>
                  <h4>Graphic Design</h4>
              </div>
              <div class="col-md-4 col-sm-6 service">
                  <div class="service-icon">
                      <i class="ion-social-buffer"></i>
                  </div>
                  <h4>Print Design</h4>
              </div>
          </div>
      </div>
     <!--  <div class="block-content">
          <h3 class="block-title">Awards</h3>
          <div class="row">
              <div class="col-md-3 col-sm-6 awards">
                  <img src="assets\images\awards\1.jpeg" alt="">
              </div>
              <div class="col-md-3 col-sm-6 awards">
                  <img src="assets\images\awards\2.jpeg" alt="">
              </div>
              <div class="col-md-3 col-sm-6 awards">
                  <img src="assets\images\awards\3.jpeg" alt="">
              </div>
              <div class="col-md-3 col-sm-6 awards">
                  <img src="assets\images\awards\4.jpeg" alt="">
              </div>
          </div>
      </div> -->
      <!-- <div class="block-content">
          <h3 class="block-title">Testimonios</h3>
          <div class="row">
              <div id="liontestimonial" class="owl-carousel owl-theme">
                  <div *ngFor="let testix of cv.testimonio">
                    <div class="item testimonials">
                        <img src="assets\images\testimonials\1.jpeg" alt="">
                        <blockquote>{{ testix.Descripcion }}
                            <p class="quote"><span>{{ testix.Nombre }}</span> - {{ testix.Cargo }}</p>
                        </blockquote>
                    </div>
                </div>

              </div>
          </div>
      </div> -->
      <div class="row text-center">
          <div class="col-md-12 btn-email">
              <a class="btn lowercase">fabienmrobin@gmail.com</a>
          </div>
      </div>
  </section>
