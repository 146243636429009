<div class="preloader">
  <div class="anim pulse"><i class="ion-ios-bolt-outline"></i></div>
</div>
<div class="preloader-left"></div>
<div class="inline-menu-container">
  <span class="status">Estoy disponible como Freelance</span>
  <ul class="inline-menu">
      <li class="about menu-item">Resumen</li>
      <li class="portfolio menu-item">Portafolio</li>
      <li class="blog menu-item">Blog</li>
      <li class="contact menu-item">Contacto</li>
      <li id="close" class="menu-item"><i class="ion-ios-close-empty"></i></li>
  </ul>
</div>
<section class="home">
<div class="bgScroll"></div>
  <div class="overlay opacity7">
  </div>
  <div class="container">

      <div class="name-block">
          <div class="name-block-container">
              <h1><span>Hola, Soy</span>Fabien MARCOS ROBIN</h1>
              <h2>Analista & Desarrollador BackEnd/FrontEnd</h2>
              <a target="_blank" href="assets/cv/Fabien_MARCOS_ROBIN_DESARROLLADOR_WEB_FULLSTACK.pdf" class="btn btn-download">Descargar CV</a>
              <ul class="social">
                  <li><a href="https://wa.me/34684361067"><i class="ion-social-whatsapp" target_="_blank"></i></a></li>
                  <li><a href="https://www.facebook.com/Fabien-Marcos-Desarrollo-103620351526968/" target_="_blank"><i class="ion-social-facebook"></i></a></li>
                  <li><a href="https://www.linkedin.com/in/fabien-marcos-robin-b9b7a51b5/" target_="_blank"><i class="ion-social-linkedin"></i></a></li>
              </ul>
          </div>
      </div>
      <div class="menu-blocks">
          <div class="about-block menu-block">
              <div class="about-block-container">
                  <h2 class="about menu-item">Resumen</h2>
              </div>
          </div>
          <div class="portfolio-block  menu-block">
              <div class="portfolio-block-container">
                  <h2 class="portfolio menu-item">Portafolio</h2>
              </div>
          </div>
          <div class="blog-block  menu-block">
              <div class="blog-block-container">
                  <h2 class="blog menu-item">Blog</h2>
              </div>
          </div>
          <div class="contact-block  menu-block">
              <div class="contact-block-container">
                  <h2 class="contact menu-item">Contacto</h2>
              </div>
          </div>
      </div>

      <div class="content-blocks about">
        <app-resumen></app-resumen>
      </div>

      <div class="content-blocks portfolio">
        <app-portafolio></app-portafolio>
      </div>
      <div class="content-blocks blog">
          <app-blog></app-blog>
      </div>
      <div class="content-blocks contact">
          <app-contacto></app-contacto>
      </div>

      <div class="content-blocks pop">
          <div id="close-pop" class="close-pop">Cerrar <i class="ion-ios-close-empty"></i></div>
          <section class="content"></section>
      </div>
    
  </div>
</section>

