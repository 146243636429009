<section class="content">
  <div class="block-content">
      <h3 class="block-title">Portafolio</h3>
      <div class="row">
          <div class="col-md-12">
              <!-- <div class="filters">
                  <span>Filters :</span>
                  <ul id="filters">
                      <li class="active" data-filter="*">All</li>
                      <li data-filter=".artwork">E-commerce</li>
                      <li data-filter=".webdesign">Web Design</li>
                      <li data-filter=".design">Design</li>
                  </ul>
              </div> -->
              <div class="portfolio-container row isotope" id="portfolio-container">
                  <!-- WORK 1 START -->
                  <div class="col-md-6 col-sm-6 col-xs-12 portfolio-item design">
                    <a class="open-project" href="../../index.html" id="proj5">
                        <div class="portfolio-column">
                            <div class="portfolio-hover">
                                <div class="portfolio-content">
                                    <h2>APP LA PEFFECTA PROMETIDA</h2>
                                    <hr>
                                    <p>Ver detalles.</p>
                                </div>
                                <div class="portfolio-overlay"></div>
                            </div>
                            <img src="assets\images\portfolio\masonry\app-lpp.webp" alt="">
                        </div>
                    </a>
                </div>
                  <!-- WORK 1 END -->
                  <!-- WORK 2 START -->
                  <div class="col-md-6 col-sm-6 col-xs-12 portfolio-item design">
                    <a class="open-project" href="../../index.html" id="proj6">
                        <div class="portfolio-column">
                            <div class="portfolio-hover">
                                <div class="portfolio-content">
                                    <h2>LA BOX ESPAGNOLE</h2>
                                    <hr>
                                    <p>Ver detalles.</p>
                                </div>
                                <div class="portfolio-overlay"></div>
                            </div>
                            <img src="assets\images\portfolio\masonry\LBE.webp" alt="">
                        </div>
                    </a>
                </div>
                  <!-- WORK 2 END -->
                  <!-- WORK 3 START -->
                  <div class="col-md-6 col-sm-6 col-xs-12 portfolio-item artwork">
                    <a class="open-project" href="../../index.html" id="proj1">
                        <div class="portfolio-column">
                            <div class="portfolio-hover">
                                <div class="portfolio-content">
                                    <h2>ESPRIT TAURIN</h2>
                                    <hr>
                                    <p>Ver detalles.</p>
                                </div>
                                <div class="portfolio-overlay"></div>
                            </div>
                            <img src="assets\images\portfolio\masonry\esprittaurin.webp" alt="">
                        </div>
                    </a>
                </div>
                  <!-- WORK 3 END -->
                  <!-- WORK 4 START -->
                  <div class="col-md-6 col-sm-6 col-xs-12 portfolio-item artwork">
                      <a class="open-project" href="../../index.html" id="proj4">
                          <div class="portfolio-column">
                              <div class="portfolio-hover">
                                  <div class="portfolio-content">
                                      <h2>CUBAS GOYO</h2>
                                      <hr>
                                      <p>Ver detalles.</p>
                                  </div>
                                  <div class="portfolio-overlay"></div>
                              </div>
                              <img src="assets\images\portfolio\masonry\cubasgoyo.webp" alt="">
                          </div>
                      </a>
                  </div>
                  <!-- WORK 4 END -->
                  <!-- WORK 5 START -->
                  <div class="col-md-6 col-sm-6 col-xs-12 portfolio-item webdesign">
                    <a class="open-project" href="../../index.html" id="proj3">
                        <div class="portfolio-column">
                            <div class="portfolio-hover">
                                <div class="portfolio-content">
                                    <h2>LA PERFECTA PROMETIDA</h2>
                                    <hr>
                                    <p>Ver detalles.</p>
                                </div>
                                <div class="portfolio-overlay"></div>
                            </div>
                            <img src="assets\images\portfolio\masonry\lpp.webp" alt="">
                        </div>
                    </a>
                </div>
                  <!-- WORK 5 END -->
                  <!-- WORK 6 START -->
                  <div class="col-md-6 col-sm-6 col-xs-12 portfolio-item webdesign">
                    <a class="open-project" href="../../index.html" id="proj2">
                        <div class="portfolio-column">
                            <div class="portfolio-hover">
                                <div class="portfolio-content">
                                    <h2>KPLANAZO</h2>
                                    <hr>
                                    <p>Ver detalles.</p>
                                </div>
                                <div class="portfolio-overlay"></div>
                            </div>
                            <img src="assets\images\portfolio\masonry\kplanazo.webp" alt="">
                        </div>
                    </a>
                </div>
                  <!-- WORK 6 END -->
                  <!-- WORK 7 START -->
                  <!-- <div class="col-md-4 col-sm-6 col-xs-12 portfolio-item webdesign">
                      <a class="open-project" href="project-1.html">
                          <div class="portfolio-column">
                              <div class="portfolio-hover">
                                  <div class="portfolio-content">
                                      <h2>LOOK</h2>
                                      <hr>
                                      <p>View details.</p>
                                  </div>
                                  <div class="portfolio-overlay"></div>
                              </div>
                              <img src="assets\images\portfolio\masonry\007.jpeg" alt="">
                          </div>
                      </a>
                  </div> -->
                  <!-- WORK 7 END -->
                  <!-- WORK 8 START -->
                  <!-- <div class="col-md-4 col-sm-6 col-xs-12 portfolio-item artwork">
                      <a class="open-project" href="project-2.html">
                          <div class="portfolio-column">
                              <div class="portfolio-hover">
                                  <div class="portfolio-content">
                                      <h2>IPAD</h2>
                                      <hr>
                                      <p>View details.</p>
                                  </div>
                                  <div class="portfolio-overlay"></div>
                              </div>
                              <img src="assets\images\portfolio\masonry\008.jpeg" alt="">
                          </div>
                      </a>
                  </div> -->
                  <!-- WORK 8 END -->
                  <!-- WORK 9 START -->
                  <!-- <div class="col-md-4 col-sm-6 col-xs-12 portfolio-item design">
                      <a class="open-project" href="project-1.html">
                          <div class="portfolio-column">
                              <div class="portfolio-hover">
                                  <div class="portfolio-content">
                                      <h2>PAPER MOCKUP</h2>
                                      <hr>
                                      <p>View details.</p>
                                  </div>
                                  <div class="portfolio-overlay"></div>
                              </div>
                              <img src="assets\images\portfolio\masonry\009.jpeg" alt="">
                          </div>
                      </a>
                  </div> -->
                  <!-- WORK 9 END -->
              </div>
          </div>
      </div>
  </div>
  <div class="row text-center">
      <div class="col-md-12 btn-email">
          <a class="btn lowercase">fabienmrobin@gmail.com</a>
      </div>
  </div>
</section>
